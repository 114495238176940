import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
// gatsby-browser.js
import React from 'react';

export const onClientEntry = () => {
  // Google Tag Manager (noscript) pour les utilisateurs sans JavaScript
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=G-TSKFDDEV9E"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(noscript);
};
