exports.components = {
  "component---src-components-interne-js": () => import("./../../../src/components/interne.js" /* webpackChunkName: "component---src-components-interne-js" */),
  "component---src-components-intervention-js": () => import("./../../../src/components/intervention.js" /* webpackChunkName: "component---src-components-intervention-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-model-js": () => import("./../../../src/pages/a-propos-model.js" /* webpackChunkName: "component---src-pages-a-propos-model-js" */),
  "component---src-pages-accueil-js": () => import("./../../../src/pages/accueil.js" /* webpackChunkName: "component---src-pages-accueil-js" */),
  "component---src-pages-back-link-js": () => import("./../../../src/pages/backLink.js" /* webpackChunkName: "component---src-pages-back-link-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-blogs-1-js": () => import("./../../../src/pages/blogs1.js" /* webpackChunkName: "component---src-pages-blogs-1-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devis-js": () => import("./../../../src/pages/devis.js" /* webpackChunkName: "component---src-pages-devis-js" */),
  "component---src-pages-doctors-js": () => import("./../../../src/pages/doctors.js" /* webpackChunkName: "component---src-pages-doctors-js" */),
  "component---src-pages-dr-profile-js": () => import("./../../../src/pages/dr-profile.js" /* webpackChunkName: "component---src-pages-dr-profile-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-procedure-model-js": () => import("./../../../src/pages/procedure-model.js" /* webpackChunkName: "component---src-pages-procedure-model-js" */),
  "component---src-pages-procedure-type-js": () => import("./../../../src/pages/procedure-type.js" /* webpackChunkName: "component---src-pages-procedure-type-js" */),
  "component---src-pages-success-2-js": () => import("./../../../src/pages/success-2.js" /* webpackChunkName: "component---src-pages-success-2-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tarif-model-js": () => import("./../../../src/pages/tarif-model.js" /* webpackChunkName: "component---src-pages-tarif-model-js" */)
}

